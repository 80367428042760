import React from 'react'
import { Link } from 'gatsby'
import caseStudy1 from '../../assets/images/case-study/visionar_case_study_ar-for-product-packaging-400x284.png'
import caseStudy2 from '../../assets/images/case-study/visionar_case_study_ar-for-business-cards-400x284.png'
import caseStudy3 from '../../assets/images/case-study/visionar_case_study_ar-for-interactive-product-catalogues-400x284.jpg'
import caseStudy4 from '../../assets/images/case-study/visionar_case_study_ar-for-interactive-learning-400x284.jpg'
import caseStudy5 from '../../assets/images/case-study/visionar_case_study_ar-for-interactive-art-installations-400x284.jpg'
import caseStudy6 from '../../assets/images/case-study/visionar_case_study_ar-for-interactive-product-instructions-400x284.jpg'
import caseStudy7 from '../../assets/images/case-study/visionar_case_study_ar-for-interactive-fashion-magazine-400x284.jpg'

// Functional components
const TwoColumns = () => {
    return (
        <div className="projects-area pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy1} alt="about" />

                                <Link to="/showcases/1" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/1">
                                        AR for product packaging
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy2} alt="about" />

                                <Link to="/showcases/2" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/2">
                                        AR for business cards
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy3} alt="about" />

                                <Link to="/showcases/3" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/3">
                                        AR for interactive product catalogues
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy4} alt="about" />

                                <Link to="/showcases/4" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/4">
                                        AR for interactive learning
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={caseStudy5} alt="about" />

                                <Link to="/showcases/5" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/5">
                                        AR for art installations
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy6} alt="about" />

                                <Link to="/showcases/6" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/6">
                                        AR for product instructions
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={caseStudy7} alt="about" />

                                <Link to="/showcases/7" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/showcases/7">
                                        AR for print magazines
                                    </Link>
                                </h3>
                                <span>Showcase</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoColumns