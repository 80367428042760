import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TwoColumns from '../components/Studies/TwoColumns'
import shape1 from '../assets/images/shape/shape1.svg';
import shape2 from '../assets/images/shape/shape2.png';
import shape3 from '../assets/images/shape/shape3.png';
import ProjectStartArea from '../components/Index/ProjectStartArea';

// Functional component
const Showcases = () => {
    return (
        <Layout>
            <Navbar />
          <div className="page-title-area">
            <div className="container">
              <div className="page-title-content">
                <h2>Augmented Reality Showcases</h2>
                
                <p>There are so many examples for augmented reality solutions out there. But only visionar offers the industry-leading simpliest way to create augmented reality with just view clicks by yourself and your own content.</p>
              </div>
            </div>

            <div className="shape-img1">
              <img src={shape1} alt="banner" />
            </div>
            <div className="shape-img2">
              <img src={shape2} alt="banner" />
            </div>
            <div className="shape-img3">
              <img src={shape3} alt="banner" />
            </div>
          </div>
            <TwoColumns />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Showcases